export enum OccurrenceType {
  // legacy
  holiday = "holiday",
  brunel = "brunel",
  // current
  leave = "leave",
  londonbrunel = "london brunel",
  singapore = "singapore",
  travel = "travel",
  newyork = "new york",
  remote = "remote",
  parentalleave = "parental leave",
  sickleave = "sick leave",
  arsenalfc = "arsenal fc",
  astonvillafc = "aston villa fc",
  afcbournemouth = "afc bournemouth",
  fulhamfc = "fulham fc",
  nottinghamforestfc = "nottingham forest fc",
  evertonfc = "everton fc",
  newcastleunited = "newcastle united",
  norwichfc = "norwich fc",
  brentfordfc = "brentford fc",
  leedsunitedfc = "leeds united fc",
  southamptonfc = "southampton fc",
  brightonandhovefc = "brighton and hove fc",
  leicestercityfc = "leicester city fc",
  ipswichtownfc = "ipswich town fc",
  tottenhamhotspurfc = "tottenham hotspur fc",
  burnleyfc = "burnley fc",
  liverpoolfc = "liverpool fc",
  watfordfc = "watford fc",
  chelseafc = "chelsea fc",
  manchestercity = "manchester city",
  westhamunited = "west ham united",
  crystalpalacefc = "crystal palace fc",
  manchesterunited = "manchester united",
  wolverhamptonfc = "wolverhampton fc",
  sheffieldunitedfc = "sheffield united fc",
  lutontownfc = "luton town fc",
}

export enum LeaveType {
  annualLeave = "Annual Leave",
  bankHoliday = "Bank Holiday",
  // legacy
  holiday = "holiday",
}

export enum OccurrenceDuration {
  fd = "fd",
  am = "am",
  pm = "pm",
}

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum OccurrenceDurationLabels {
  "Full day" = "Full day",
  Morning = "Morning",
  Afternoon = "Afternoon",
}

export enum ValidationError {
  endDate = "endDate",
  repeatEvery = "repeatEvery",
  weekDays = "weekDays",
}

export enum Unit {
  Day = "Day",
  Week = "Week",
}

export enum IntervalDirection {
  next = "next",
  previous = "previous",
}

export enum SettingsSections {
  notifications = "notifications",
  dashboard = "dashboard",
  office = "office",
  delegates = "delegates",
}

export enum NotificationsSettingsOptions {
  dailyNotification = "dailyNotification",
  weeklyNotification = "weeklyNotification",
  onlyOwnTeam = "onlyOwnTeam",
}

export enum DashboardSettingsOptions {
  defaultView = "defaultView",
}

export enum OfficeSettingsOptions {
  defaultOffice = "defaultOffice",
}

export enum DelegatesSettingsOptions {
  delegatesTo = "delegatesTo",
  delegatesFor = "delegatesFor",
}

export enum DefaultView {
  calendar = "calendar",
  week = "week",
  fortnight = "fortnight"
}

export enum DefaultOffice {
  londonbrunel = "london brunel",
  newyork = "new york",
  singapore = "singapore",
}

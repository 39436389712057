import { AppUserViewModel } from "../types";
import { Box, Spinner, Tip } from "grommet";
import { Pin } from "grommet-icons";
import { colors } from "../assets/theme";
import React from "react";

export const PinUser = ({
  appUser,
  isLoading,
  onFavouritesClick,
}: {
  appUser: AppUserViewModel;
  isLoading: boolean;
  onFavouritesClick: (appUser: AppUserViewModel) => void;
}) => {
  const handleClick = () => {
    onFavouritesClick(appUser);
  };

  return (
    <Box width="35px" justify="center" align="center" onClick={handleClick}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Tip content={<Box pad="10px">Unpin user</Box>}>
          <Pin
            id="pink-pin"
            color={colors.plMagenta06}
            size="small"
            style={{ cursor: "pointer" }}
          />
        </Tip>
      )}
    </Box>
  );
};

import {
  AppUserViewModel,
  DateInterval,
  OccurrenceViewModel,
  Team,
  TeamViewModel,
} from "../types";
import { DefaultView } from "../enums";
import useMediaQuery from "../hooks/ueMediaQuery";
import React, { useState } from "react";
import { Box, Notification } from "grommet";
import { canManage, RowEventBox } from "../utils";
import { AppUserBadge } from "./AppUserBadge";
import { PinUser } from "./PinUser";
import { UnpinUser } from "./UnpinUser";
import { DotPulse } from "./DotPulse/DotPulse";
import { Info } from "grommet-icons";
import { OccurrencesRow } from "./OccurrencesRow";
import styled from "styled-components";

const EventsBox = styled(Box)`
  flex: 2;
`;

export const TeamCardBody = ({
  datesInterval,
  allUsers,
  team,
  withSeparator,
  isLoading,
  appUserId,
  onSelectDate,
  onFavouritesClick,
  isPinningUserId,
  favouriteAppUsers,
  activeTimeSpan,
  filterValue,
  showingTheWholeMonth,
  showPinOnHover,
}: {
  datesInterval: DateInterval[];
  team: TeamViewModel | Team;
  withSeparator?: boolean;
  showingTheWholeMonth?: boolean;
  showPinOnHover: boolean;
  isLoading: boolean;
  isPinningUserId?: string;
  appUserId?: string;
  filterValue?: string;
  onSelectDate: (
    date: string,
    occurrences: OccurrenceViewModel[],
    targetUser: AppUserViewModel
  ) => void;
  onFavouritesClick?: (appUser: AppUserViewModel) => void;
  favouriteAppUsers?: AppUserViewModel[];
  allUsers: AppUserViewModel[];
  activeTimeSpan?: DefaultView;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  const [showManagerRightsToast, setShowManagerRightsToast] = useState<{
    value: boolean;
    selectedUserName: string;
  }>({ value: false, selectedUserName: "" });

  const teamDataSource = () => {
    return !filterValue
      ? team?.appUsers
      : team?.appUsers.filter((appUser) =>
          appUser.name
            .toLowerCase()
            .includes(filterValue.toLowerCase().replace(/\s/g, ""))
        );
  };

  const getMarginLeftForIntervalRow = () => {
    // if showingTheWholeMonth it means we are in the Teams page, otherwise in Dashboard
    switch (true) {
      case isDesktop:
        return showingTheWholeMonth ? "190px" : "225px";
      default:
        return showingTheWholeMonth ? "30px" : "65px";
    }
  };

  return (
    <Box
      data-testid={"teams-body"}
      direction="column"
      overflow={{ horizontal: "auto", vertical: "hidden" }}
    >
      <Box
        direction="row"
        justify={
          !isDesktop && activeTimeSpan === DefaultView.week ? "end" : "start"
        }
        flex="grow"
        margin={{
          left: getMarginLeftForIntervalRow(),
        }}
      >
        {datesInterval.map((date, i) => {
          const isToday =
            new Date(date.date as unknown as Date).setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0);
          return (
            <RowEventBox
              clickable={false}
              greenSideBorder={isToday}
              key={`${date.weekDay}${i}`}
              greyOverlay={
                date.weekDay === "saturday" || date.weekDay === "sunday"
              }
            >
              {date.weekDay.charAt(0).toUpperCase()}
            </RowEventBox>
          );
        })}
      </Box>
      <Box
        direction="row"
        justify={
          !isDesktop && activeTimeSpan === DefaultView.week ? "end" : "start"
        }
        flex="grow"
        margin={{
          left: getMarginLeftForIntervalRow(),
        }}
      >
        {datesInterval?.map((date, i) => {
          const isToday =
            new Date(date.date as unknown as Date).setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0);
          return (
            <RowEventBox
              clickable={false}
              greenSideBorder={isToday}
              key={`${date}${i}`}
              greyOverlay={
                date.weekDay === "saturday" || date.weekDay === "sunday"
              }
            >
              {date.number}
            </RowEventBox>
          );
        })}
      </Box>
      {teamDataSource().length > 0 ? (
        teamDataSource().map((appUser, i) => (
          <Box
            key={appUser?.id}
            direction="row"
            align="center"
            style={{ minHeight: "35px" }}
            margin={{
              bottom: i === team.appUsers.length - 1 ? "medium" : "unset",
            }}
          >
            <Box direction="row">
              <Box
                id="avatar"
                direction="row"
                align="center"
                data-testid={`${appUser.name}`}
              >
                <AppUserBadge
                  showPinOnHover={showPinOnHover}
                  onClick={() => onFavouritesClick!(appUser)}
                  appUserId={appUserId}
                  appUser={appUser}
                  withSeparator={withSeparator}
                />
                {showPinOnHover ? (
                  !!favouriteAppUsers?.find(
                    (favUser) => favUser.id === appUser.id
                  ) ? (
                    <PinUser
                      appUser={appUser}
                      onFavouritesClick={(appUser) =>
                        !isPinningUserId ? onFavouritesClick!(appUser) : null
                      }
                      isLoading={isPinningUserId === appUser.id}
                    />
                  ) : (
                    <UnpinUser
                      appUser={appUser}
                      onFavouritesClick={(appUser) =>
                        !isPinningUserId ? onFavouritesClick!(appUser) : null
                      }
                      isLoading={isPinningUserId === appUser.id}
                    />
                  )
                ) : null}
              </Box>
            </Box>
            <EventsBox
              direction="column"
              margin={{ left: isDesktop ? "unset" : "6px" }}
            >
              {!isLoading ? (
                <Box
                  direction="row"
                  justify={
                    isDesktop ||
                    (!isDesktop &&
                      (activeTimeSpan === DefaultView.fortnight ||
                        showingTheWholeMonth))
                      ? "start"
                      : "end"
                  }
                >
                  <OccurrencesRow
                    days={appUser?.days}
                    appUser={appUser}
                    iconSize="20"
                    isLastChild={i === team.appUsers.length - 1}
                    onSelectDate={async (date, occurrences) => {
                      const mainAppUser = allUsers.find(
                        (u) => u.id === appUserId
                      );

                      // check if AppUser has rights to log data for the selectedAppUser
                      if (
                        mainAppUser &&
                        canManage(mainAppUser, appUser, allUsers)
                      ) {
                        onSelectDate(date, occurrences, appUser);
                        return;
                      }
                      setShowManagerRightsToast({
                        value: true,
                        selectedUserName: appUser.name.replace(/[.]/g, " "),
                      });
                    }}
                  />
                </Box>
              ) : (
                <Box height="35px" direction="row" justify="center">
                  <DotPulse />
                </Box>
              )}
            </EventsBox>
          </Box>
        ))
      ) : (
        <Box margin={{ vertical: "20px" }} align="center">
          There are no users to show.
        </Box>
      )}
      {showManagerRightsToast?.value ? (
        <Notification
          icon={<Info />}
          toast={{
            autoClose: true,
            position: "top",
          }}
          time={3000}
          title={`You cannot create or amend events for ${showManagerRightsToast.selectedUserName}.`}
          onClose={() =>
            setShowManagerRightsToast({ value: false, selectedUserName: "" })
          }
        />
      ) : null}
    </Box>
  );
};
